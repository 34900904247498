import { Global, css } from "@emotion/react";

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }
      body {
        margin: 0;
      }
      .MuiDataGrid-row:hover,
      button:hover {
       transition: 0.3s;
      }
      a.MuiButton-contained:hover {
        color: #fff;
        transition: 0.3s;
      }
      // .MuiInputBase-input:not([type="email"]) {
      //   text-transform: uppercase;
      // }
      .password-input .MuiInputBase-input {
        text-transform: unset;
      }
      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }
      .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        transition: 0.3s;
      }
      .MuiDataGrid-toolbarContainer {
        margin: 10px 10px 0;
      }
      body > iframe {
        pointer-events: none;
      }
      a {
        margin: 5px 0;
      }
      a:hover {
        color: rgb(32, 87, 35);
        transition: 0.3s;
      }     
      .escolha-tipo-usuario a:hover {
        color: #fff;
        transition: 0.3s;
      } 
      button:focus {
        outline: none;
      }
      button:disabled,
      button[disabled]{
        opacity: .5;
      }
      button.hideAction,
      a.hideAction {
        display: none;
      }
      .footer-button {
        width: fit-content !important;
      }
      .footer-button span {
        margin-right: 0 !important;
      }
      .alert-dialog .MuiDialog-paper p {
        margin-top: 20px;
      }
      .hzero-brand {
        bottom: 0;
        max-width: 100% !important;
        justify-content: center !important;
      }
      .datagrid {
        background: #fff;
      }
      .datagrid.edicao-rapida,
      .datagrid.lancamento-financeiro, 
      .datagrid.ordem-producao,
      .datagrid.planos-conta,
      .datagrid.separacoes,
      .datagrid.pedidos,
      .datagrid.produtos,
      .datagrid.nfs, {
        height: 70vh;
      }
      .datagrid .MuiDataGrid-main>div:last-child {
        display: none;
      }
      .datagrid .MuiTablePagination-root p {
        margin-bottom: 0;
      }
      .datagrid MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
      }
      .alert-dialog .MuiDialog-paper p,
      .alert-dialog .MuiDialog-paper .receber-pedido-description p,
      .alert-dialog .MuiDialog-paper .adicionar-produto-description p,
      .alert-dialog .MuiDialog-paper .receber-ordem-description p,
      .alert-dialog .MuiDialog-paper .detalhe-parcelas-description p {
        margin-top: unset;
      }
      .css-axafay-MuiDataGrid-virtualScroller {
        min-height: 70px;
      }
      .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
      }
      .MuiDataGrid-row--editing .MuiDataGrid-cell {
        background-color: #ebebeb !important;
      }
      .MuiDataGrid-row--editing .MuiDataGrid-cell--editing,
      .MuiDataGrid-row--editing .MuiDataGrid-cell--withRenderer {
        background-color: #fff !important;
      }
      .Mui-checked+.MuiSwitch-track {
        background-color: #35cd38 !important;
      }
      .MuiSwitch-switchBase .MuiSwitch-thumb {
        color: #fff;
      }
      .MuiDataGrid-row .actions button,
      .MuiDataGrid-row .actions a {
        color: #000;
      }
      .cor-valor {
        color: #000;
      }
      .cor-valor.positivo {
        color: #3dcd13;
      }
      .cor-valor.negativo {
        color: #ff0000;
      }
      .cor-valor.positivo {
        color: #3dcd13;
      }
      .lista-impressao p {
        font-size: 11pt;
      }
      .lista-impressao .agrovile-info-cabecalho {
        font-weight: bold;
        margin-bottom: 0;
      }
      .lista-impressao .detalhes-recibo p {
        margin-bottom: .3rem;
        margin-top: .3rem;
        margin-left: 1rem;
      }
      .menu-icon {
        height: 20px;
        width: 20px;
        display: block;
        margin: auto;
        -webkit-filter: invert(1);

        
        filter: invert(1);
      }
      .botao-buscar-cep {
        margin-top: unset !important;
        height: 100%;
      }
      .MuiDialog-container .MuiPaper-root {
        max-width: unset;
        box-shadow: unset;
      }
    `}
  />
);

export default GlobalStyle;
