import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import { alteracaoSenhaUsuario } from "../../api/usuarios";
import {
  Alert as MuiAlert,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import SenhaInput from "../../components/forms/Senha/SenhaInput";
import FeedbackDialog from "../dialogs/FeedbackDialog";

const Alert = styled(MuiAlert)(spacing);

function AlteracaoSenha() {
  const location = useLocation()
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const params = new URLSearchParams(location.search)

      // debugger;
      await alteracaoSenhaUsuario(values, params.get("email"), params.get("token"))

      setSucesso(true);
      setStatus({ sent: true });
      setSubmitting(false);
      openAlert();
    } catch (error) {
      setStatus({ sent: false });
      setError(error);
      setSubmitting(false);
      openAlert();
    }
  }

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const openAlert = () => {
    setOpen(true);
  };

  return (
    <Formik
      initialValues={{
        senha: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        senha: Yup.string().max(255).required("O campo é obrigatório"),
      //   senha: Yup.string()
      //     .required("Campo obrigatória")
      //     .min(10, 'Senha curta demais - deve conter ao menos 10 caracteres.')
      //     .minLowercase(1, 'A senha deve conter ao menos uma letra em caixa baixa.')
      //     .minUppercase(1, 'A senha deve conter ao menos uma letra em caixa alta.')
      //     .minNumbers(1, 'A senha deve conter ao menos um número.'),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <FeedbackDialog
            open={open}
            onClose={handleClose}
            sucesso={sucesso}
            error={error}
            mensagem="Senha alterada com sucesso"
            closeBtnLabel="Voltar para tela de login"
            closeBtnAction={() => navigate("/login")}
          />

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={2}>
                <div>
                  <Typography variant="h3" gutterBottom>
                    Nova senha
                  </Typography>
                  <Typography sx={{ marginBottom: 5, marginTop: 5 }}>Digite sua nova senha abaixo</Typography>
                </div>
                <SenhaInput
                  id="senha"
                  name="senha"
                  label="Senha"
                  value={values.senha}
                  error={Boolean(touched.senha && errors.senha)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormHelperText>
                  A senha deve conter ao menos 10 caracteres, sendo pelo menos um caracter em caixa alta, um caracter em caixa baixa e um número.
                </FormHelperText>

                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Alterar senha
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </React.Fragment>
      )}
    </Formik>
  );
}

export default AlteracaoSenha;